import {useEffect, useState} from "react";
import {Country, State, City} from 'country-state-city';
import '../../sass/apps/weather.scss'
import {FaLocationDot, FaTemperatureArrowUp} from "react-icons/fa6";
import {WiHumidity, WiNightCloudyGusts} from "react-icons/wi";
import {GiDirectionSigns, GiPressureCooker} from "react-icons/gi";
import {MdSunny, MdVisibility} from "react-icons/md";
import {FaCloud} from "react-icons/fa";
import {RiEmotionHappyFill, RiSpeedMiniFill} from "react-icons/ri";
import {BsFillSunriseFill, BsFillSunsetFill} from "react-icons/bs";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import Login from "../account/logInWithEmailAndPassword";
import useUser from "../account/useUser";
import Button from "../comman/button";
import NotfoundImg from "../../assests/404.gif";
import LoginImg from '../../assests/forgot_password.gif'

const Weather = () => {

    const apiKey = 'cebece9559e2dbf4592daf61e154ccee';
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [country, setCountry] = useState('IN');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [weather, setWeather] = useState(null);
    const [error, setError] = useState("");

    const allCountries = Country.getAllCountries();
    const allStates = State.getStatesOfCountry(country);
    const allCities = City.getCitiesOfState(country, state);

    const {user} = useUser();

    // useEffect(() => {
    //
    //     geoLocation();
    // }, []);

    const geoLocation = () => {
        if (navigator.geolocation) {
            setWeather(null);
            navigator.geolocation.getCurrentPosition((p) => (
                setLatitude(p.coords.latitude), setLongitude(p.coords.longitude)
            ));
        } else {
            setError("Geolocation is not supported by this browser.");
        }
    }
    // console.log("City :" + city)
    // console.log("Latitude :" + latitude)
    // console.log("Longitude :" +longitude)

    useEffect(() => {
        const getWeather = async () => {
            setWeather(null);
            const data = await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${apiKey}`).then(response => response.json());
            setWeather(data);
        }

        if ((latitude && longitude) !== "") {
            getWeather();
        }

    }, [latitude, longitude]);

    const date = new Date();

    const convertTimestamp = (timestamp) => {
        let d = new Date(timestamp * 1000), // Convert the passed timestamp to milliseconds
            hh = d.getHours(),
            h = hh,
            min = ('0' + d.getMinutes()).slice(-2),     // Add leading 0.
            ampm = 'AM',
            time;

        if (hh > 12) {
            h = hh - 12;
            ampm = 'PM';
        } else if (hh === 12) {
            h = 12;
            ampm = 'PM';
        } else if (hh === 0) {
            h = 12;
        }

        // ie: 3:00 PM
        time = h + ':' + min + ' ' + ampm;
        return time;
    }

    const box = ({children}) => {
        return (
            <div
                style={{
                    marginLeft: 10,
                    marginRight: 10,
                    marginBottom: 0,

                }}
            >
                {children}
            </div>
        );
    }

    console.log(weather)

    if (!user){
        return (
            <div className={'container'}>
                <section className={'notFoundSection'}>
                    <img src={LoginImg} alt={'Login'} height={350} width={350}/>
                    <p>You need to login to access the app. </p>
                    <Button path={'/login'} title={'Login'}/>
                </section>
            </div>
        )
    }
    if (user) {
        return (
            <div className={'weatherApp'}>
                <h1 className={'app-title'}>Weather</h1>
                <div className={'container'}>

                    <div className={'weatherAppWrapper'}>
                        <div className={'form-field'}>
                        <form>
                                <label className={'from-heading'}>Select Location</label>
                                <div className={'select-list'}>
                                    <div className={'select-field'}>
                                        <select value={country} onChange={e => setCountry(e.target.value)}>
                                            {allCountries.map((e) => (
                                                <option value={e.isoCode} key={e.name}>{e.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className={'select-field'}>
                                        <select value={state} onChange={e => setState(e.target.value)}>
                                            <option>Select State</option>
                                            {allStates.map((e) => (
                                                <option value={e.isoCode} key={e.name}>{e.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className={'select-field'}>
                                        <select value={city} onChange={e => (
                                            setCity(e.target.value),
                                                setLatitude(e.target.options[e.target.selectedIndex].getAttribute("data-lat")), setLongitude(e.target.options[e.target.selectedIndex].getAttribute("data-long"))
                                        )}>
                                            <option>Select City</option>
                                            {allCities.map((e) => (
                                                <option value={e.name} data-lat={e.latitude}
                                                        data-long={e.longitude} key={e.name}>{e.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </form>
                            <p className={'note'}>OR</p>
                            <div className={'button-wrap'}>
                                <button type={"button"} className={'button'} onClick={() => geoLocation()}>
                                    <FaLocationDot/>Get current
                                    location
                                </button>
                            </div>
                        </div>
                        <div className={'report-field'}>
                            <div className={'weather-table'}>
                                {weather?.message ?
                                    <p className={'message'}>{weather.message}</p>
                                    :
                                    <>
                                        <div className={'top-section'}>
                                            <div className={'top-head'}>
                                                <p className={'title'}>Current Weather</p>
                                                <span className={'city'}><FaLocationDot/>{weather ? weather.name :
                                                    <SkeletonTheme baseColor="#ffffff4f" highlightColor="#f5f5f51f">
                                                        <Skeleton width={100} count={1}/>
                                                    </SkeletonTheme>
                                                }</span>
                                            </div>

                                            <span className={'date'}>{date.toLocaleDateString()}</span>
                                            <div className={'details'}>
                                                <div className={'main-data'}>
                                                    <div className={'weather-icon-wrap'}>
                                                        {weather ?
                                                            <img
                                                                src={`http://openweathermap.org/img/w/${weather?.weather[0].icon}.png`}
                                                                height={'100'} width={'100'} alt={'weather Icon'}/>
                                                            :
                                                            <SkeletonTheme baseColor="#ffffff4f"
                                                                           highlightColor="#f5f5f51f">
                                                                <Skeleton width={100} circle={'true'} count={1}
                                                                          height={100}/>
                                                            </SkeletonTheme>
                                                        }
                                                        {weather ?
                                                            <span
                                                                className={'temp'}>{Math.round(weather?.main.temp - 273.15)}°C
                                                            </span>

                                                            :
                                                            <SkeletonTheme baseColor="#ffffff4f"
                                                                           highlightColor="#f5f5f51f">
                                                                <Skeleton width={200} count={3} wrapper={box}
                                                                          height={20}/>
                                                            </SkeletonTheme>
                                                        }
                                                    </div>
                                                    <h3 className={'description'}>{weather ? weather.weather[0].description :
                                                        <SkeletonTheme baseColor="#ffffff4f" highlightColor="#f5f5f51f">
                                                            <Skeleton width={150} count={1}/>
                                                        </SkeletonTheme>
                                                    }

                                                    </h3>
                                                    {weather ?
                                                        <p className={'max-temp'}><FaTemperatureArrowUp/>The high will
                                                            be {Math.round(weather.main.temp_max - 273.15)}°C
                                                        </p>
                                                        :
                                                        <p className={'max-temp'}>
                                                            <SkeletonTheme baseColor="#ffffff4f"
                                                                           highlightColor="#f5f5f51f">
                                                                <Skeleton width={250} count={1}/>
                                                            </SkeletonTheme>
                                                        </p>
                                                    }
                                                </div>
                                                <div className={'sun-time'}>
                                                    <MdSunny className={'sunIcon'}/>
                                                    {weather ?
                                                        <span className={'sun-item-data'}>
                                                        <BsFillSunriseFill/> Sunrise : {convertTimestamp(weather?.sys.sunrise)}
                                                    </span>
                                                        :
                                                        <div className={'loader-wrap'}>
                                                            <SkeletonTheme baseColor="#ffffff4f"
                                                                           highlightColor="#f5f5f51f">
                                                                <Skeleton wrapper={box} count={1} width={"100%"}
                                                                          height={18}/>
                                                            </SkeletonTheme>
                                                        </div>
                                                    }
                                                    {weather ?
                                                        <span className={'sun-item-data'}>
                                                       <BsFillSunsetFill/> Sunset : {convertTimestamp(weather?.sys.sunset)
                                                        }
                                                    </span>
                                                        :
                                                        <div className={'loader-wrap'}>
                                                            <SkeletonTheme baseColor="#ffffff4f"
                                                                           highlightColor="#f5f5f51f">
                                                                <Skeleton wrapper={box} count={1} width={"100%"}
                                                                          height={18}/>
                                                            </SkeletonTheme>
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                        <ul className={'field-list'}>
                                            {weather ?
                                                <li className={'list-item'} key={weather?.main.feels_like}>
                                                <span
                                                    className={'list-item-data'}><RiEmotionHappyFill/>Feels Like : {Math.round(weather.main.feels_like - 273.15)}°C</span>
                                                </li>
                                                :
                                                <li className={'list-item-loader'}>
                                                    <SkeletonTheme baseColor="#ffffff4f" highlightColor="#f5f5f51f">
                                                        <Skeleton count={1}/>
                                                    </SkeletonTheme>
                                                </li>
                                            }
                                            {weather ?
                                                <li className={'list-item'} key={weather?.main.humidity}>
                                                <span
                                                    className={'list-item-data'}><WiHumidity/>Humidity : {weather.main.humidity}%</span>
                                                </li>
                                                :
                                                <li className={'list-item-loader'}>
                                                    <SkeletonTheme baseColor="#ffffff4f" highlightColor="#f5f5f51f">
                                                        <Skeleton count={1}/>
                                                    </SkeletonTheme>
                                                </li>
                                            }
                                            {weather ?
                                                <li className={'list-item'} key={weather?.main.pressure}>
                                                    <span
                                                        className={'list-item-data'}><GiPressureCooker/>Pressure : {weather.main.pressure} hPa</span>
                                                </li>
                                                :
                                                <li className={'list-item-loader'}>
                                                    <SkeletonTheme baseColor="#ffffff4f" highlightColor="#f5f5f51f">
                                                        <Skeleton count={1}/>
                                                    </SkeletonTheme>
                                                </li>
                                            }
                                            {weather ?
                                                <li className={'list-item'} key={weather?.visibility}>
                                                <span
                                                    className={'list-item-data'}><MdVisibility/>Visibility : {weather.visibility / 1000} km</span>
                                                </li>
                                                :
                                                <li className={'list-item-loader'}>
                                                    <SkeletonTheme baseColor="#ffffff4f" highlightColor="#f5f5f51f">
                                                        <Skeleton count={1}/>
                                                    </SkeletonTheme>
                                                </li>
                                            }
                                            {weather ?
                                                <li className={'list-item'} key={weather?.clouds.all}>
                                                <span
                                                    className={'list-item-data'}><FaCloud/>Cloudiness : {weather.clouds.all}%</span>
                                                </li>
                                                :
                                                <li className={'list-item-loader'}>
                                                    <SkeletonTheme baseColor="#ffffff4f" highlightColor="#f5f5f51f">
                                                        <Skeleton count={1}/>
                                                    </SkeletonTheme>
                                                </li>
                                            }

                                        </ul>
                                        {weather ?
                                            <h3 className={'sub-description'}>Wind</h3>
                                            :
                                            <h3 className={'sub-description'}>
                                                <SkeletonTheme baseColor="#ffffff4f" highlightColor="#f5f5f51f">
                                                    <Skeleton count={1} width={100}/>
                                                </SkeletonTheme>
                                            </h3>
                                        }
                                        <ul className={'field-list'}>
                                            {weather ?
                                                <li className={'list-item'}>
                                                <span
                                                    className={'list-item-data'}><RiSpeedMiniFill/>Speed : {weather.wind.speed} m/sec</span>
                                                </li>
                                                :
                                                <li className={'list-item-loader'}>
                                                    <SkeletonTheme baseColor="#ffffff4f" highlightColor="#f5f5f51f">
                                                        <Skeleton count={1}/>
                                                    </SkeletonTheme>
                                                </li>
                                            }
                                            {weather ?
                                                <li className={'list-item'}>
                                                <span
                                                    className={'list-item-data'}><GiDirectionSigns/>Direction : {weather.wind.deg}°</span>
                                                </li>
                                                :
                                                <li className={'list-item-loader'}>
                                                    <SkeletonTheme baseColor="#ffffff4f" highlightColor="#f5f5f51f">
                                                        <Skeleton count={1}/>
                                                    </SkeletonTheme>
                                                </li>
                                            }
                                            {weather ?
                                                <li className={'list-item'}>
                                                <span
                                                    className={'list-item-data'}><WiNightCloudyGusts/>Gust : {weather.wind.gust} m/sec</span>
                                                </li>
                                                :
                                                <li className={'list-item-loader'}>
                                                    <SkeletonTheme baseColor="#ffffff4f" highlightColor="#f5f5f51f">
                                                        <Skeleton count={1}/>
                                                    </SkeletonTheme>
                                                </li>
                                            }
                                        </ul>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Weather;
